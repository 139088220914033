<template>
  <div>
    <v-dialog v-model="dialogDeleteOrders" max-width="500px">
      <v-card>
        <v-card-title class="headline">
          Are you sure you want to delete all orders?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="dialogDeleteOrders = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="deleteAllOrders()">
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteSettings" max-width="500px">
      <v-card>
        <v-card-title class="headline">
          Are you sure you want to delete this?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDeleteSettings = false"
          >
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="confirmDeleteSetting()">
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteProducts" max-width="500px">
      <v-card>
        <v-card-title class="headline">
          Are you sure you want to delete all products?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDeleteProducts = false"
          >
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="deleteAllProducts()">
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Settings</v-toolbar-title>
      </v-toolbar>
      <v-tabs vertical>
        <v-tab> FTP Config</v-tab>
        <v-tab> Orders </v-tab>
        <v-tab> Products </v-tab>
        <v-tab> Apk Upload </v-tab>
        <v-tab> Settings </v-tab>
        <v-tab> Backup </v-tab>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-col class="text-right ma-0 pa-0">
                <app-btn class="mx-2" @click="dialog = true">
                  <v-icon>mdi-view-grid-plus</v-icon> Add FTP
                </app-btn>
              </v-col>
              <v-data-table
                :headers="ftpHeaders"
                :items="ftps"
                :footer-props="{
                  showFirstLastPage: true,
                  'items-per-page-options': [5, 10, 15],
                }"
              >
                <template v-slot:top>
                  <v-dialog v-model="dialog" max-width="500px">
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-form ref="OrderForm">
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedItem.name"
                                  label="Name"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedItem.type"
                                  label="Type"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedItem.host"
                                  label="Host"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedItem.port"
                                  label="Port"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedItem.to_File"
                                  label="Device Path"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedItem.from_File"
                                  label="Server Path"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedItem.ftP_Username"
                                  label="Username"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedItem.ftP_Password"
                                  label="Password"
                                />
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn color="blue darken-1" text @click="close">
                          Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="headline">
                        Are you sure you want to delete this item?
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn color="blue darken-1" text @click="closeDelete">
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm"
                        >
                          OK
                        </v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    medium
                    color="info"
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon medium color="error" @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="pa-4">
            <app-btn
              v-if="canDeleteOrder"
              class="mx-2"
              @click="dialogDeleteOrders = true"
            >
              Delete All Orders
            </app-btn>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="pa-4">
            <app-btn
              v-if="canDeleteProduct"
              class="mx-2"
              @click="dialogDeleteProducts = true"
            >
              Delete All Products
            </app-btn>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="pa-4">
            <v-row class="mt-4">
              <v-col cols="12" md="4">
                <v-file-input v-model="apkFile" label="Apk File" />
              </v-col>
              <app-btn class="mt-4" @click="uploadApkFile"> Upload </app-btn>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="pa-4">
            <div class="mb-4">
              <app-btn class="mx-2" @click="dialogAddDeviceSettings = true">
                Add
              </app-btn>
            </div>
            <v-data-table
              :headers="deviceSettingsHeaders"
              :items="deviceSettings"
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-options': [5, 10, 15],
              }"
            >
              <template v-slot:top>
                <v-dialog v-model="dialogAddDeviceSettings" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add Settings</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-form ref="OrderForm">
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="settingsItem.name"
                                label="Name"
                              />
                            </v-col>
                            <v-col cols="12">
                              <!-- <v-text-field
                                v-model="settingsItem.type"
                                label="Type"
                              /> -->
                              <v-combobox
                                v-model="settingsItem.type"
                                :items="settingsTypes"
                                label="Type"
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="settingsItem.value"
                                label="Value"
                              />
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogAddDeviceSettings = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="addSettings">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">
                      Are you sure you want to delete this item?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="blue darken-1" text @click="closeDelete">
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                      >
                        OK
                      </v-btn>
                      <v-spacer />
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  medium
                  color="info"
                  class="mr-2"
                  @click="editSettingsItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon medium color="error" @click="deleteSettingsItem(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="pa-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="sourceConnectionStringId"
                :items="connectionStrings"
                item-text="name"
                item-value="id"
                label="Source"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="destinationConnectionStringId"
                :items="connectionStrings"
                item-text="name"
                item-value="id"
                label="Destination"
              />
            </v-col>
            <v-col cols="12" md="4" class="text-right">
              <app-btn @click="backup"> Backup </app-btn>
            </v-col>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>
<script>
import SettingsDataService from "../services/SettingsDataService";
import OrderDataService from "../services/OrderDataService";
import ProductDataService from "../services/ProductDataService";
import DataTables from "./n/DataTables.vue";

export default {
  name: "Settings",
  components: { DataTables },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogDeleteOrders: false,
    dialogDeleteProducts: false,
    dialogAddDeviceSettings: false,
    dialogDeleteSettings: false,
    destinationConnectionStringId: 0,
    sourceConnectionStringId: 0,
    connectionStrings: "",
    ftpHeaders: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      {
        text: "Type",
        value: "type",
      },
      {
        text: "Host",
        value: "host",
      },
      {
        text: "Port",
        value: "port",
      },
      {
        text: "To File",
        value: "to_File",
      },
      {
        text: "From File",
        value: "from_File",
      },
      {
        text: "Username",
        value: "ftP_Username",
      },
      {
        text: "Password",
        value: "ftP_Password",
      },
      {
        text: "Actions",
        align: "end",
        value: "actions",
      },
    ],
    deviceSettingsHeaders: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Type",
        value: "type",
      },
      {
        text: "Value",
        value: "value",
      },
      { text: "Actions", value: "actions" },
    ],
    deviceSettings: [],
    ServerList: [
      {
        text: "https://myordersmmc.azurewebsites.net/",
        value: "https://myordersmmc.azurewebsites.net/",
      },
    ],
    settingsTypes: ["server-url", "db-connection-string"],
    apkFile: [],
    ftps: [],
    selectedDevices: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      type: "server-url",
      host: "",
      port: "",
      to_File: "",
      from_File: "ApkVersion",
      ftP_Username: "",
      ftP_Password: "",
      username: "",
    },
    default: {
      name: "",
      type: "server-url",
      host: "",
      port: "",
      to_File: "",
      from_File: "ApkVersion",
      ftP_Username: "",
      ftP_Password: "",
      username: "",
    },
    settingsItemIndex: -1,
    settingsItem: {
      name: "",
      type: "",
      value: "",
    },
    defaultSettingsItem: {
      name: "",
      type: "",
      value: "",
    },
    permissions: localStorage.getItem("permissions"),
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New FTP" : "Edit FTP";
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    canDeleteProduct() {
      return this.permissions.includes("Permissions.Products.Delete");
    },
    canDeleteOrder() {
      return this.permissions.includes("Permissions.Orders.Delete");
    },
    initialize() {
      SettingsDataService.getAllFtpConfig().then((response) => {
        this.ftps = response.data;
      });
      SettingsDataService.settingsGetAll().then((response) => {
        this.deviceSettings = response.data;
      });
      SettingsDataService.getDbConnectionStrings().then((response) => {
        this.connectionStrings = response.data;
      });
    },
    editItem(item) {
      this.editedIndex = this.ftps.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.ftps.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        SettingsDataService.updateFtpConfig(this.editedItem).then(
          (response) => {}
        );
        Object.assign(this.ftps[this.editedIndex], this.editedItem);
        this.$toast.success("FTP config edited successfully.", "Success", {
          position: "topRight",
        });
      } else {
        SettingsDataService.createFtpConfig(this.editedItem).then(
          (response) => {
            this.ftps.push(response.data);
            this.$toast.success("FTP config added successfully.", "Success", {
              position: "topRight",
            });
          }
        );
      }
      this.close();
    },
    deleteItemConfirm() {
      SettingsDataService.deleteFtpConfig(this.editedItem.id).then(
        (response) => {
          if (response.status === 200) {
            this.$toast.success("Product deleted successfully.", "Success", {
              position: "topRight",
            });
            this.ftps.splice(this.editedIndex, 1);
          } else {
            this.$toast.error(
              "Something went wrong. please try again later",
              "Error",
              {
                position: "topRight",
              }
            );
          }
        }
      );
      this.closeDelete();
    },
    deleteAllOrders() {
      OrderDataService.deleteAllOrders().then((response) => {
        if (response.status == 200) {
          this.$toast.success("Orders deleted successfully.", "Success", {
            position: "topRight",
          });
        }
      });
      this.dialogDeleteOrders = false;
    },
    deleteAllProducts() {
      ProductDataService.deleteAllProducts().then((response) => {
        if (response.status == 204) {
          this.$toast.success("Products deleted successfully.", "Success", {
            position: "topRight",
          });
        }
      });
      this.dialogDeleteProducts = false;
    },
    uploadApkFile() {
      if (this.apkFile) var bodyFormData = new FormData();
      bodyFormData.append("FormFile", this.apkFile);
      SettingsDataService.uploadApk(bodyFormData).then((response) => {
        if (response.data.state == "success") {
          this.apkFile = [];
          this.$toast.success("Apk file uploaded successfully.", "Success", {
            position: "topRight",
          });
        }
      });
    },
    addSettings() {
      if (this.settingsItemIndex > -1) {
        SettingsDataService.updateSettings(this.settingsItem).then(
          (response) => {
            if (response.status == 200) {
              this.$toast.success("Settings updated successfully.", "Success", {
                position: "topRight",
              });
              this.initialize();
            }
          }
        );
      } else {
        SettingsDataService.addSettings(this.settingsItem).then((response) => {
          if (response.status == 200) {
            this.$toast.success("Settings added successfully.", "Success", {
              position: "topRight",
            });
            this.initialize();
          }
        });
      }
      this.dialogAddDeviceSettings = false;
    },
    editSettingsItem(item) {
      this.settingsItem = Object.assign({}, item);
      this.settingsItemIndex = this.deviceSettings.indexOf(item);
      this.dialogAddDeviceSettings = true;
    },
    deleteSettingsItem(item) {
      this.settingsItem = Object.assign({}, item);
      this.settingsItemIndex = this.deviceSettings.indexOf(item);
      this.dialogDeleteSettings = true;
    },
    confirmDeleteSetting() {
      SettingsDataService.deleteSettings(this.settingsItem.id).then(
        (response) => {
          if (response.status == 200) {
            this.$toast.success("Settings deleted successfully.", "Success", {
              position: "topRight",
            });
            this.initialize();
          }
        }
      );
      this.dialogDeleteSettings = false;
    },
    backup() {
      var data = {
        sourceId: this.sourceConnectionStringId,
        destinationId: this.destinationConnectionStringId,
      };
      SettingsDataService.migrateData(data).then((response) => {
        if (response.status == 200) {
          this.$toast.success("Backed up successfully.", "Success", {
            position: "topRight",
          });
          this.initialize();
        }
      });
    },
  },
};
</script>
